<template>
    <div class="equipment-list">
        <!-- banner -->
        <mall-banner
            router-name="化妆品专区入口"
            router-path="/cosmetics">
        </mall-banner>
        <div class="container">
            <!-- filter -->
            <list-filter
                :sort-list="testSort">
                <template #filter>
                    <filter-list
                        filter-title="相机分类："
                        :filter-array="testArray1">
                    </filter-list>
                    <filter-list
                        filter-title="摄影器材："
                        :filter-array="testArray2">
                    </filter-list>
                </template>
            </list-filter>
            <!-- list -->
            <ul class="equipment-list-item clearfix">
                <li v-for="(item, index) in 12"
                    :key="index">
                    <goods-item
                        router-path="/equipment/detail">
                    </goods-item>
                </li>
            </ul>
            <!-- page -->
            <div class="equipment-list-page">
                <el-pagination
                    layout="prev, pager, next"
                    :page-count="100"
                    :pager-count="11">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                testArray1: [
                    {
                        value: 1,
                        label: "单电/微单"
                    },
                    {
                        value: 2,
                        label: "单反相机"
                    },
                    {
                        value: 3,
                        label: "数码相机"
                    },
                    {
                        value: 4,
                        label: "拍立得"
                    },
                    {
                        value: 5,
                        label: "旁轴相机"
                    },
                    {
                        value: 6,
                        label: "摄像机"
                    },
                    {
                        value: 7,
                        label: "LOMO相机"
                    },
                    {
                        value: 8,
                        label: "特殊相机"
                    },
                    {
                        value: 9,
                        label: "135胶片单反"
                    }
                ],
                testArray2: [
                    {
                        value: 1,
                        label: "摄影附件"
                    },
                    {
                        value: 2,
                        label: "闪光配件"
                    },
                    {
                        value: 3,
                        label: "摄影灯类"
                    },
                    {
                        value: 4,
                        label: "摄影背景"
                    },
                    {
                        value: 5,
                        label: "三脚架类"
                    },
                    {
                        value: 6,
                        label: "摄影棚套装"
                    },
                    {
                        value: 7,
                        label: "补光灯区"
                    }
                ],
                testSort: [
                    {
                        value: 1,
                        label: "热门推荐"
                    },
                    {
                        value: 2,
                        label: "销量最多"
                    },
                    {
                        value: 3,
                        label: "最新产品"
                    }
                ]
            }
        },
        components: {
            mallBanner: () => import("@/components/imageMall/mallBanner"),
            listFilter: () => import("@/components/imageMall/listFilter"),
            filterList: () => import("@/components/equipment/filterList"),
            goodsItem: () => import("@/components/imageMall/goodsItem")
        }
    }
</script>

<style scoped>
.equipment-list{
    --color: #8d9afc;
}
/* list */
.equipment-list-item > li{
    float: left;
    width: 25%;
    padding: 10px;
    margin-top: 50px;
}

/* page */
.equipment-list-page{
    margin: 50px 0;
    text-align: center;
}
</style>